
import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted,
  onBeforeMount,
  watch,
  onActivated,
} from "vue";
import store, { MutationNames } from "@/store/index";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { getBooks, getTeacherBooks, getTeacherInfo } from "@/services";
import { loadMicroApp } from "qiankun";
import servers from "@/config/servers";
import config from "@/config/app.config";
import HomeBackground from "@/components/common/HomeBackground";
import {
  eventCenter,
  event,
  usePageTableContext,
} from "@evideo/frontend-utils";
import pageTableContextMap from "@/utils/page-table-context-map";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import _ from "lodash";
import logger from "@evideo/logger";
import TeachProgressMemory from "@/components/TeachProgressMemory.vue";
import MorePPTModal from "@/components/MorePPTModal.vue";

export default defineComponent({
  components: {
    HomeBackground,
    Swiper,
    SwiperSlide,
    TeachProgressMemory,
    MorePPTModal,
  },
  setup() {
    logger.info("教材列表页初始化");
    let loading = ref(false);
    let preloadPptFun = "";
    const router = useRouter();
    const route = useRoute();
    const teacherName = ref(store.state.nickName);
    const schoolName = ref(store.state.schoolName);
    let bookType = ref("");
    let message = ref("");
    let total = ref(1);
    // 教材当前所在页数默认在第一页
    let current_page = ref(1);
    let teachMaterialeight = reactive([]);
    let teachMaterialList = reactive([]);
    let switchTeachMaterialList = reactive([]);
    let middleTeachMaterialeight = reactive([]);
    let lastTeachMaterialeight = reactive([]);
    let slideTotalList = reactive([]);
    let reloadData = false;
    let mySwiper = ref(null);
    let swiperPage = ref(1);
    let num = ref(10);
    let firstLoading = ref(true);
    let leftBorderFlag = ref(true);
    let rightBorderFlag = ref(true);
    let isAndroid = ref(false);
    // 等待加载完成
    let isWaitReady = ref(true);
    // 是否劢联定制版
    let isML = ref(false);
    const schoolLogo = computed(() => {
      return store.state.schoolLogo;
    });
    // 版本限制
    const versionLimit = ref(false);
    const device = navigator.userAgent;
    const android =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    if (android) {
      isAndroid.value = true;
    }
    // official:教材中心 ｜｜myCourse:我的教材 ｜｜characteristicClassroom:特色课堂
    bookType.value = route.query.teachMaterialType;
    // const androidToken =
    //   // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNDIwNTc1MywiZXhwIjoxNjY5NzM3NzUzLCJuYmYiOjE2MzQyMDU3NTMsImp0aSI6IkNSQ0lIaFBYb2lCUUExTFkiLCJzdWIiOjE3NywicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.iRhmc0KRXTThBiY5X4WfulAgTOt5pLQpKhCEhUX_ZZg";
    //   // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNDAyMTM4NCwiZXhwIjoxNjM0NjI2MTg0LCJuYmYiOjE2MzQwMjEzODQsImp0aSI6IlZHdHZFRU1nNlpZS1N2MHYiLCJzdWIiOjEyMywicHJ2IjoiYjI3YmVlMjNiYWY1NDI5ZjViOWEyMTY5NmZkZTAzYzI3NzA0NGVhNSJ9.wqY29o1JSdRT5I1ZIUjhB_znJvwgvoAwrjuaELi8l6o";
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNTQwMTAyMSwiZXhwIjoxNjcwOTMzMDIxLCJuYmYiOjE2MzU0MDEwMjEsImp0aSI6InFqeDh0b0lZQW5BNGZJSjkiLCJzdWIiOjEyMywicHJ2IjoiYjI3YmVlMjNiYWY1NDI5ZjViOWEyMTY5NmZkZTAzYzI3NzA0NGVhNSJ9.9FpFxm0OBJfvhVIumfc8QQhdO_RfYtfEmiLCHq9tqEQ";
    // store.commit(MutationNames.CHANGE_TOKEN, androidToken);

    const contextPage = ref(1);
    const { getContextInfo, saveContextInfo, deleteContextInfo } =
      usePageTableContext();

    // 从教材详情返回时读取上次离开时的页码等参数
    const handleGetContextInfo = () => {
      const context = getContextInfo(pageTableContextMap.MATERIAL_LIST);
      logger.info("get context ===> ", context);
      const { currentPage } = context;
      contextPage.value = currentPage;
      deleteContextInfo(pageTableContextMap.MATERIAL_LIST);
    };

    // 保存当前页码
    const handleSaveContextInfo = () => {
      const pageContext = {
        currentPage: current_page.value,
      };
      logger.info("save context ===> ", pageContext);
      saveContextInfo(pageTableContextMap.MATERIAL_LIST, pageContext);
    };

    onBeforeMount(() => {
      isML.value = store.state.isML;
      teacherName.value = store.state.nickName;
      schoolName.value = store.state.schoolName;
      // if (reloadData) {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.once("teachMaterialGoBack", (event, message) => {
          // 接收electron原生返回的信息
          logger.info("返回home", event, message);
          goToHome();
        });
      }
      handleGetContextInfo();
      logger.info("看看教材页面有没有保存当前页===>", contextPage.value);
      getBookInfo(contextPage.value);
      // getTeacherInfo({}).then((data) => {
      //   teacherName.value = data.nick_name;
      //   store.commit(MutationNames.CHANGE_NICKNAME, data.nick_name);
      // });
      // }
    });
    onMounted(() => {
      // const iscompare = compareVersion(store.state.version, "1.4.1");
      // if (iscompare >= 0) {
      //   // 暂时先将快速跳转功能关闭
      //   versionLimit.value = true;
      // }
      versionLimit.value = handleVersion();
      logger.info("teachMaterialeight", teachMaterialeight);
      logger.info("teachMaterialList====>", teachMaterialList);
      init();
      // teachMaterialeight.push(...teachMaterialList.slice(0, 8));
      firstLoading.value = false;
    });
    onActivated(() => {
      bookType.value = route.query.teachMaterialType;
      teacherName.value = store.state.nickName;
      schoolName.value = store.state.schoolName;
      if (isAndroid.value) {
        logger.info("通知android显示");
        window.WebViewJavascriptBridge.callHandler(
          "loadSuccess", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
      }
      // 预加载ppt
      // if (store.state.firstLoadPPT) {
      //   console.time("开始");
      //   // loadPPT();
      //   loading.value = true;
      //   store.commit(MutationNames.CHANGE_FIRSTLOADPPT, false);
      // }
      // 接收上级应用 预加载完成，可以卸载应用了
      // eventCenter.sub("teachPptPC-preloadOk", () => {
      //   console.timeEnd("开始");
      //   eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, { app: "teachPptPC" });
      //   loading.value = false;
      // });
      if (reloadData) {
        if (window.require) {
          const { ipcRenderer } = window.require("electron");
          ipcRenderer.once("teachMaterialGoBack", (event, message) => {
            // 接收electron原生返回的信息
            goToHome();
          });
        }
        getBookInfo();
      }
    });
    const loadPPT = async () => {
      if (!window.qiankunStarted) {
        window.qiankunStarted = true;
      }
      const env = JSON.stringify(process.env.RELEASE_ENV);
      // let entryUrl = "http://192.168.113.138:8090/";
      let entryUrl = config[servers.pptist];
      if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
        entryUrl = localStorage.entryUrl;
      }
      eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
        app: "teachPptPC",
        el: "#ppt-preload",
        entry: entryUrl,
        args: {
          token: store.state.token,
          appName: "teachPptPC",
          preload: true,
        },
        configuration: { $$cacheLifecycleByAppName: true },
      });
    };
    const getBookInfo = (contextPage) => {
      logger.info("看看教材页面有没有保存当前页===>", contextPage);
      if (contextPage === undefined) {
        contextPage = 1;
      }
      loading.value = true;
      logger.info("重新获取数据", bookType.value);
      teachMaterialList.length = 0;
      teachMaterialeight.length = 0;
      // current_page.value = contextPage;
      // total.value = 1;
      if (bookType.value === "official") {
        getBooks({ per_page: 10, show_modules: 1, page: contextPage }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if (contextPage % 3 == 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 1;
            }
            if (contextPage % 3 == 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 2;
            }
            if (contextPage % 3 == 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 3;
            }
            // 判断结束
            if (contextPage === 1 && total.value !== 1) {
              leftBorderFlag.value = false;
              rightBorderFlag.value = true;
            } else if (contextPage === total.value && total.value === 1) {
              leftBorderFlag.value = false;
              rightBorderFlag.value = false;
            } else if (contextPage === total.value && total.value !== 1) {
              leftBorderFlag.value = true;
              rightBorderFlag.value = false;
            } else {
              leftBorderFlag.value = true;
              rightBorderFlag.value = true;
            }
            current_page.value = contextPage;
          }
        );
        getBooks({ per_page: 10, show_modules: 1, page: contextPage + 1 }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if ((contextPage + 1) % 3 === 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
            }
            if ((contextPage + 1) % 3 === 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
            }
            if ((contextPage + 1) % 3 === 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
            }
            // 判断结束
          }
        );
        getBooks({ per_page: 10, show_modules: 1, page: contextPage - 1 }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if ((contextPage - 1) % 3 === 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
            }
            if ((contextPage - 1) % 3 === 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
            }
            if ((contextPage - 1) % 3 === 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
            }
            // 判断结束
          }
        );
      }
      if (bookType.value === "characteristicClassroom") {
        getBooks({ per_page: 10, show_modules: 2, page: contextPage }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if (contextPage % 3 == 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 1;
            }
            if (contextPage % 3 == 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 2;
            }
            if (contextPage % 3 == 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 3;
            }
            // 判断结束
            if (contextPage === 1 && total.value !== 1) {
              leftBorderFlag.value = false;
              rightBorderFlag.value = true;
            } else if (contextPage === total.value && total.value === 1) {
              leftBorderFlag.value = false;
              rightBorderFlag.value = false;
            } else if (contextPage === total.value && total.value !== 1) {
              leftBorderFlag.value = true;
              rightBorderFlag.value = false;
            } else {
              leftBorderFlag.value = true;
              rightBorderFlag.value = true;
            }
            current_page.value = contextPage;
          }
        );
        getBooks({ per_page: 10, show_modules: 2, page: contextPage + 1 }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if ((contextPage + 1) % 3 === 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
            }
            if ((contextPage + 1) % 3 === 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
            }
            if ((contextPage + 1) % 3 === 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
            }
            // 判断结束
          }
        );
        getBooks({ per_page: 10, show_modules: 2, page: contextPage - 1 }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if ((contextPage - 1) % 3 === 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
            }
            if ((contextPage - 1) % 3 === 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
            }
            if ((contextPage - 1) % 3 === 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
            }
            // 判断结束
          }
        );
      }
      if (bookType.value === "myCourse") {
        // 我的课件
        getTeacherBooks({ per_page: 10, page: contextPage }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if (contextPage % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...res.data.items);
            total.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 1;
          }
          if (contextPage % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...res.data.items);
            total.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 2;
          }
          if (contextPage % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...res.data.items);
            total.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 3;
          }
          // 判断结束
          if (contextPage === 1 && total.value !== 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = true;
          } else if (contextPage === total.value && total.value === 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = false;
          } else if (contextPage === total.value && total.value !== 1) {
            leftBorderFlag.value = true;
            rightBorderFlag.value = false;
          } else {
            leftBorderFlag.value = true;
            rightBorderFlag.value = true;
          }
          current_page.value = contextPage;
        });
        getTeacherBooks({ per_page: 10, page: contextPage + 1 }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((contextPage + 1) % 3 === 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...res.data.items);
          }
          if ((contextPage + 1) % 3 === 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...res.data.items);
          }
          if ((contextPage + 1) % 3 === 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...res.data.items);
          }
          // 判断结束
        });
        getTeacherBooks({ per_page: 10, page: contextPage - 1 }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((contextPage - 1) % 3 === 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...res.data.items);
          }
          if ((contextPage - 1) % 3 === 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...res.data.items);
          }
          if ((contextPage - 1) % 3 === 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...res.data.items);
          }
          // 判断结束
        });
      }
      loading.value = false;
      setTimeout(() => {
        loading.value && (loading.value = false);
      }, 60000);
    };
    // 快捷跳转状态重置
    const resetMinimizeState = () => {
      store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
      store.commit(MutationNames.CHANGE_MINIMIZEURL, "");
      store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, "");
      store.commit(MutationNames.CHANGE_PPTTREEID, "");
      store.commit(MutationNames.CHANGE_MORERESOURCEDATA, "");
      store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, "");
      store.commit(MutationNames.CHANGE_MOREPPTINFO, "");
      store.commit(MutationNames.CHANGE_MOREPPTSHOW, false);
    };
    const goToMaterialPpt = (id, url, name) => {
      reloadData = false;
      // 进入教材前，先将当前的数据保存下来
      handleSaveContextInfo();
      // 点击教材列表去除最小化
      if (store.state.minimizeBtnState === "/materialPpt") {
        store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
      }
      logger.info("url", url);
      logger.info("name", name);
      resetMinimizeState();
      router.push({
        path: "/materialPpt",
        query: {
          bookId: id,
          url,
          name: encodeURI(name),
          firstLoadType: false,
          teachMaterialType: bookType.value,
        },
      });
    };
    const goToHome = () => {
      reloadData = true;
      teachMaterialList.length = 0;
      teachMaterialeight.length = 0;
      current_page.value = 1;
      total.value = 1;
      router.push({
        path: "/home",
      });
      // history.back();
    };
    const goToLogin = () => {
      reloadData = true;
      teachMaterialList.length = 0;
      teachMaterialeight.length = 0;
      current_page.value = 1;
      total.value = 1;
      if (isAndroid.value) {
        logger.info("消息 通知安卓关闭教材列表页");
        // window.WebViewJavascriptBridge.callHandler(
        //   "closeTeachMaterial", // native中注册时的key值
        //   null // native中注册方法中的传入的数据
        // );
        router.replace({
          path: "/blank",
          query: {
            skinType: 1,
          },
          // path: "/login",
        });
      } else {
        router.push({
          // path: "/home",
          path: "/login",
        });
      }

      // history.back();
    };
    const goWhiteboard = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goControl = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    //  点击按钮切换到下一页
    const next = _.debounce(() => {
      if (!isWaitReady.value) {
        return;
      }
      logger.info("点击了按钮切换到下一页的页数是===>", current_page.value + 1);
      if (current_page.value === total.value) {
        message.value = "已经是最后一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slideNext();
      }
    }, 200);
    //  点击按钮切换到上一页
    const previous = _.debounce(() => {
      if (!isWaitReady.value) {
        return;
      }
      logger.info("点击了按钮切换到上一页的页数是===>", current_page.value - 1);
      if (current_page.value === 1) {
        message.value = "已经是第一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slidePrev();
      }
    }, 200);
    //  页面切换到上一页
    const onSlidePrevTransitionEnd = () => {
      const currentActiveIndex = mySwiper.value.activeIndex;
      const nextPage = current_page.value - 1;
      const nextPageMOD = nextPage % 3;
      const currentPageMOD = currentActiveIndex % 3;
      if (nextPageMOD === currentPageMOD) {
        mySwiper.value.touches.diff = 0;
        logger.info("当前窗口是在==>", mySwiper.value.activeIndex);
        logger.info(
          "页面切换到上一页函数里拿到的当前页数是===>",
          current_page.value - 1
        );
        current_page.value--;
        isWaitReady.value = false;
        getPrevData(current_page.value - 1);
      }
    };
    //  页面切换到下一页
    const onSlideNextTransitionEnd = () => {
      if (!firstLoading.value) {
        const currentActiveIndex = mySwiper.value.activeIndex;
        const nextPage = current_page.value + 1;
        const nextPageMOD = nextPage % 3;
        const currentPageMOD = currentActiveIndex % 3;
        if (nextPageMOD === currentPageMOD) {
          mySwiper.value.touches.diff = 0;
          logger.info("触发slider,当前页数为===>", current_page.value + 1);
          logger.info(
            "现在的slide里的数据是:===>",
            teachMaterialeight,
            middleTeachMaterialeight,
            lastTeachMaterialeight
          );
          current_page.value++;
          isWaitReady.value = false;
          getNextData(current_page.value + 1);
        }
      }
    };
    //  切换时获取下一页数据的函数
    const getNextData = (page) => {
      if (bookType.value === "official") {
        // 教材
        getBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
          show_modules: 1,
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value + 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
          isWaitReady.value = true;
        });
      }
      if (bookType.value === "characteristicClassroom") {
        // 教材
        getBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
          show_modules: 2,
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value + 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
          isWaitReady.value = true;
        });
      }
      if (bookType.value === "myCourse") {
        // 我的课件
        getTeacherBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value + 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
          isWaitReady.value = true;
        });
      }
    };
    //  切换时获取上一页数据的函数
    const getPrevData = (page) => {
      if (bookType.value === "official") {
        logger.info("official");
        // 教材
        getBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
          show_modules: 1,
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info(
            "即将请求上一页数据，拿数据时的页面是===>",
            current_page.value
          );
          if ((current_page.value - 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
          isWaitReady.value = true;
        });
      }
      if (bookType.value === "characteristicClassroom") {
        logger.info("characteristicClassroom");
        // 教材
        getBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
          show_modules: 2,
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value - 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
          isWaitReady.value = true;
        });
      }
      if (bookType.value === "myCourse") {
        // 我的课件
        logger.info("myCourse");
        getTeacherBooks({
          per_page: 10,
          page: page,
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value - 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
          isWaitReady.value = true;
        });
      }
    };
    //  初始化滑屏
    const init = () => {
      mySwiper.value = document.querySelector(".mySwiperTM").swiper;
    };

    // 从更多ppt资源组件中获取打开窗口相关函数
    const Func = [];
    const handleOpenMorePPT = (data) => {
      Func.push(data);
    };
    const handleOpenMoreResource = (data) => {
      const handleOpen = Func[0];
      handleOpen(data);
    };

    const compareVersion = (v1, v2) => {
      // -1|v2大于v1；0|相同；1|v2小于v1
      v1 = v1.split(".");
      v2 = v2.split(".");
      const len = Math.max(v1.length, v2.length);

      while (v1.length < len) {
        v1.push("0");
      }
      while (v2.length < len) {
        v2.push("0");
      }

      for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i]);
        const num2 = parseInt(v2[i]);

        if (num1 > num2) {
          return 1;
        } else if (num1 < num2) {
          return -1;
        }
      }

      return 0;
    };
    // 根据版本号限制功能显示
    const handleVersion = () => {
      // store里取出版本号
      const version = store.state.version.split(".");
      // 需要限制功能的版本号
      const limitedVersion = [1, 4, 1];
      // 对比版本号
      for (let i = 0; i < version.length; i++) {
        const num = version[i].charCodeAt() - "0".charCodeAt();
        if (num < limitedVersion[i]) {
          return false;
        }
      }
      return true;
    };

    watch(
      () => current_page.value,
      () => {
        if (current_page.value === 1) {
          leftBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          leftBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      },
      { immediate: true }
    );
    watch(
      () => current_page.value,
      () => {
        logger.info("总页数", total.value);
        if (current_page.value === total.value) {
          rightBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          rightBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      }
    );
    watch(
      () => mySwiper.value,
      _.debounce(() => {
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX > 0 &&
          mySwiper.value.touches.diff > 0 &&
          current_page.value === 1
        ) {
          message.value = "已经是第一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX < 0 &&
          mySwiper.value.touches.diff < 0 &&
          current_page.value === total.value
        ) {
          message.value = "已经是最后一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
      }, 200),
      { deep: true }
    );
    const onSwiper = (swiper) => {
      logger.info("swiper", swiper);
    };
    // [slideChange]的事件函数。在当前Slide切换到另一个Slide时执行
    const onSlideChange = () => {
      logger.info("发生切换,切换到===>", current_page.value);
    };
    return {
      loading,
      total,
      current_page,
      teacherName,
      schoolName,
      teachMaterialeight,
      middleTeachMaterialeight,
      lastTeachMaterialeight,
      bookType,
      mySwiper,
      swiperPage,
      slideTotalList,
      num,
      firstLoading,
      goToHome,
      goToLogin,
      goToMaterialPpt,
      goWhiteboard,
      goControl,
      switchTeachMaterialList,
      leftBorderFlag,
      rightBorderFlag,
      previous,
      next,
      isAndroid,
      isML,
      onSwiper,
      onSlideChange,
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      init,
      getNextData,
      getPrevData,
      isWaitReady,
      handleOpenMorePPT,
      handleOpenMoreResource,
      versionLimit,
      schoolLogo,
    };
  },
});

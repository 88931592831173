
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import store, { MutationNames } from "@/store";
import {
  getCacheFile,
  uploadCacheFile,
  uploadCacheFileFormData,
} from "@/services/localResource";
import { getCoursewares } from "@/services";
import { inject } from "vue";
import logger from "@evideo/logger";
import _ from "lodash";
import LocalResourceExchange from "@evideo/local-resource-exchange";
import { ElMessage } from "element-plus";
import { SocketEventMap } from "@/utils/socket-event";
import {
  checkCurrentCacheDownloading,
  subCurrentCacheDownloadNum,
} from "@/utils/localResourceParse";
import moment from "moment";

export default defineComponent({
  name: "TeachProgressMemory",
  props: {
    handleOpenMoreResource: Function,
    bookType: String,
    goToMemory: Function,
  },
  setup(props: any) {
    const router = useRouter();
    const canUseHightLocalResource = computed(() =>
      _.gte(store.state.version, "1.4.3")
    );
    // 教材类型 根据不同页面显示不同数据
    const materialType = ref(0);
    // 最近课时
    const period = ref<TeachPeriond>({
      label: "暂未使用",
      localState: "",
      localDownloadProgress: undefined,
      pptUrl: "",
      courseware_count: 0,
      id: 0,
    });
    // 下一课时
    const nextPeriod = ref<TeachPeriond>({
      label: "暂未使用",
      localState: "",
      localDownloadProgress: undefined,
      pptUrl: "",
      courseware_count: 0,
      id: 0,
    });
    // 最近教材
    const material = ref<TeachMaterial>({
      name: "暂未使用",
      id: 0,
      url: "http://",
      type: "offical",
    });
    // 是否需要缓存服务
    const localResourceState = ref(store.state.localResourceState);

    // 存储精品课件全部信息
    const courseWarePPT = ref<CourseWare>({ name: "" });
    const courseWarePPTNext = ref<CourseWare>({ name: "" });

    // 存储精品课件教学记忆需要使用的数据
    const courseWarePPTMemory = ref<CourseWareLocalCache>({
      name: "没有精品课件",
    });
    const courseWarePPTMemoryNext = ref<CourseWareLocalCache>({
      name: "没有精品课件",
    });
    // 下载缓存资源,更新缓存按钮状态
    const socket: any = inject("socket");

    const downloadStatus = (res: any) => {
      const url = canUseHightLocalResource.value
        ? res.data.details[0].file_url
        : res.data.details[0].cloud_url;
      const status =
        res.data.details[0].status === 0 ? "success" : "no-download";
      const currentProgress = res.data.details[0].status === 0 ? 100 : 0;
      if (canUseHightLocalResource.value) {
        if (res.data.details[0].type === "PPT") {
          updateStatusById(
            res.data.details[0].cloud_id,
            status || "loading",
            url,
            currentProgress
          );
        }
      } else {
        updateStatus(url, status || "loading");
      }
    };
    const downloadProgress = (res: any) => {
      console.log("#message progress: ", res);
      if (!canUseHightLocalResource.value) {
        return;
      }
      updateDownProgress(res.data);
    };

    socket.on(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
    socket.on(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
    // 更新下载进度
    const updateDownProgress = (data: any) => {
      const { complete_file_num, total_file_num, cloud_id } = data || {};
      const currentProgress = total_file_num
        ? Math.floor((complete_file_num / total_file_num) * 100)
        : 0;
      if (cloud_id === period.value.id) {
        period.value.localDownloadProgress = currentProgress;
      } else if (cloud_id === nextPeriod.value.id) {
        nextPeriod.value.localDownloadProgress = currentProgress;
      } else if (cloud_id === courseWarePPTMemory.value.id) {
        courseWarePPTMemory.value.localDownloadProgress = currentProgress;
      } else if (cloud_id === courseWarePPTMemoryNext.value.id) {
        courseWarePPTMemoryNext.value.localDownloadProgress = currentProgress;
      }
    };

    // 获取教学记忆需要的精品课件数据
    const getCourseWarePPT = async (data: TeachPeriond, type: boolean) => {
      console.log("获取精品课件 start!!!!!");
      console.log("参数是", data);
      if (!data) {
        console.log("获取到的课件数据为空");
        return;
      }
      // 获取到课时对应的更多ppt资源
      if (data?.courseware_count > 0) {
        // 第一次获取ppt数据, 获取到总页数
        let res: any = await getCoursewares({
          book_section_id: data.id,
          is_primary: 0,
          page: 1,
        });
        let totalPage = res.data.meta.last_page;
        console.log("第一次获取到的总页数", totalPage);
        // 如果总页数>1,继续获取全部ppt数据
        if (totalPage > 1) {
          // 接口默认每页获取ppt数为 10 根据总页数获取全部ppt数量范围
          totalPage = 10 * res.data.meta.last_page;
          res = await getCoursewares({
            book_section_id: data.id,
            is_primary: 0,
            per_page: totalPage,
          });
        }
        // 获取到ppt数据数组
        const ppt = res.data.items;
        console.log("请求接口获取到的精品课件数据", res);
        console.log("totalPage 总课件数 ", totalPage);
        console.log("获取到的完整精品课件数据", ppt);
        // 调用获取ppt函数-
        const findPPT = findCourseWare(ppt);
        console.log("最受欢迎的ppt是", findPPT);
        // 存储下一课时数据
        // type == true 获取当前课时 type == false 获取下一课时
        if (type) {
          courseWarePPT.value = findPPT;
          console.log(courseWarePPT.value);
          getCourseWareMemory();
        } else {
          courseWarePPTNext.value = findPPT;
          console.log(courseWarePPTNext.value);
          getCourseWareMemory();
        }
        // 根据配置的内容去请求本地资源服务，更新数据
        if (localResourceState.value) {
          updateLocalResourceStatus();
        }
      } else {
        console.log("没有更多ppt资源");
      }
    };

    // 查找最受欢迎的ppt
    const findCourseWare = (data: CourseWare[]) => {
      // 如果不存在对应数据返回空值
      if (!data) {
        return {};
      } else {
        // 根据polularity 返回最受欢迎的ppt
        let max: CourseWare = {
          popularity: { popularity: 0 },
          updated_at: "0",
        };
        for (let i of data) {
          if (
            i.popularity.popularity >= max.popularity.popularity &&
            i.type === "ppt"
          ) {
            // 如果受欢迎程度相同,返回最近更新的ppt
            if (i.popularity.popularity == max.popularity.popularity) {
              compareUpdate(i.updated_at as string, max.updated_at as string)
                ? (max = i)
                : max;
            }
            max = i;
          }
        }
        return max;
      }
    };

    // 对比更新时间函数
    const compareUpdate = (date1: string, date2: string) => {
      // 时间格式: "2022-09-19 14:26:11"
      const a = new Date(date1);
      const b = new Date(date2);
      return a.getTime() > b.getTime();
    };

    // 跳转教学记忆
    const handleGoToMemory = (memory: string) => {
      store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, "");
      router.push({
        path: "/materialPpt",
        query: {
          bookId: material.value.id,
          url: material.value.url,
          name: encodeURI(material.value.name),
          firstLoadType: "false",
          teachMaterialType: material.value.type,
          memory,
        },
      });
    };

    // 快速进入精品课件
    const handleGotoCourseWarePPT = (isPeriod: boolean) => {
      store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, "");
      // 改变store中的moreResoureceClickData 实现更多ppt窗口/精品课件中的跳转
      if (isPeriod) {
        store.commit(
          MutationNames.CHANGE_MORERESOURCECLICKDATA,
          courseWarePPT.value
        );
      } else {
        store.commit(
          MutationNames.CHANGE_MORERESOURCECLICKDATA,
          courseWarePPTNext.value
        );
      }
      const morePPT = isPeriod ? "morePeriod" : "morePeriodNext";
      router.push({
        path: "/materialPpt",
        query: {
          bookId: material.value.id,
          url: material.value.url,
          name: encodeURI(material.value.name),
          firstLoadType: "false",
          teachMaterialType: material.value.type,
          memory: morePPT,
        },
      });
    };

    // 格式化教学记忆需要使用的精品课件数据
    const getCourseWareMemory = () => {
      let cul: CourseWareLocalCache = { name: "没有精品课件" };
      let next: CourseWareLocalCache = { name: "没有精品课件" };
      if (courseWarePPT.value?.id) {
        cul = {
          name: courseWarePPT.value.name as string,
          updated_at: courseWarePPT.value.updated_at,
          md5: courseWarePPT.value.md5,
          id: courseWarePPT.value.id,
          url: courseWarePPT.value.url,
        };
        courseWarePPTMemory.value = cul;
      }
      if (courseWarePPTNext.value?.id) {
        next = {
          name: courseWarePPTNext.value.name as string,
          updated_at: courseWarePPTNext.value.updated_at,
          md5: courseWarePPTNext.value.md5,
          id: courseWarePPTNext.value.id,
          url: courseWarePPTNext.value.url,
        };
        courseWarePPTMemoryNext.value = next;
      }
    };

    const handleUpdateData = (type: string, updateObj: any) => {
      for (const key in updateObj) {
        const value = updateObj[key] as string;
        if (type === "period") {
          (period.value as any)[key] = value;
        } else if (type === "nextPeriod") {
          (nextPeriod.value as any)[key] = value;
        } else if (type === "ppt") {
          (courseWarePPTMemory as any)[key] = value;
        } else {
          (courseWarePPTMemoryNext.value as any)[key] = value;
        }
      }
    };

    // 下载本地缓存
    const handleLocalResource = (data: any, type: string) => {
      if (canUseHightLocalResource.value && !checkCurrentCacheDownloading()) {
        return;
      }
      handleUpdateData(type, {
        localState: "downloading",
        localDownloadProgress: undefined,
      });

      if (canUseHightLocalResource.value) {
        const params = {
          url: data.pptUrl || data.url,
          version_time: data.version_time || data.updated_at,
          cloud_id: data.id,
          name: (data.label || data.name) + ".json",
          mode: type,
          source: "official",
          type: "ppt",
        };
        (window as any).requestIdleCallback(() => {
          LocalResourceExchange.resourceParse(params)
            .then((res: any) => {
              let formData = new FormData();
              formData.append("version_time", params.version_time);
              formData.append("mode", params.mode);
              formData.append("cloud_id", params.cloud_id);
              formData.append("name", params.name);
              formData.append("resource_file", res);
              formData.append("type", params.type.toUpperCase());
              formData.append("source", params.source);
              uploadCacheFileFormData(formData)
                .then((_res) => {
                  handleUpdateData(type, {
                    localState: "downloading",
                    localDownloadProgress: 0,
                  });
                  console.log("创建缓存资源下载成功:", _res);
                })
                .catch((err) => {
                  console.error(err);
                  // resetLocalResource(type);
                  handleUpdateData(type, {
                    localState: "no-download",
                    localDownloadProgress: undefined,
                  });
                  ElMessage.error({
                    offset: 200,
                    message: "资源下载失败",
                    type: "error",
                  });
                });
            })
            .catch((err) => {
              console.error(err);
              // resetLocalResource(type);
              handleUpdateData(type, {
                localState: "no-download",
                localDownloadProgress: undefined,
              });
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            })
            .finally(() => {
              subCurrentCacheDownloadNum();
            });
        });
      } else {
        const params = [
          {
            url: data.pptUrl || data.url,
            version_time: data.version_time || data.updated_at,
            md5: data.md5,
            id: data.id,
            name: data.label || data.name,
            mode: type,
          },
        ];
        uploadCacheFile(params);
      }
    };

    // 处理获取到的本地存储的教学记忆
    const handleLocalMemory = async (memory: string) => {
      // 如果本地存储为空 / 获取数据失败
      if (memory === '"memory"' || !memory) {
        console.log("获取本地教学记忆数据失败", memory);
        return;
      }
      const localMemory: Memory[] = JSON.parse(memory);
      console.log("获取到的本地存储数据为", localMemory);
      // 确认本地存储是否有对应老师的教学记忆
      const teacher = store.state.teacherId;
      const school = store.state.schoolId;
      let memoryIndex = -1;
      // 获取本地数据关于当前老师的教学记忆下标
      const findIndex = [];
      for (let i = 0; i < localMemory.length; i++) {
        if (localMemory[i].teacher === teacher) {
          findIndex.push(i);
        }
      }
      // 根据学校情况查找教学记忆
      if (findIndex.length > 1) {
        // 多校情况查找老师在相应学校的教学记忆
        for (let i = 0; i < findIndex.length; i++) {
          if (localMemory[findIndex[i]].school === school) {
            memoryIndex = findIndex[i];
            break;
          }
          console.log("老师在当前学校没有对应的教学记忆");
        }
      } else if (findIndex.length === 1) {
        // 当前老师本地数据只对应一间学校
        const index = findIndex[0];
        if (localMemory[index].school === school) {
          memoryIndex = index;
        } else {
          console.log("老师在当前学校没有对应的教学记忆");
        }
      } else {
        // 没有找到老师的教学记忆
        console.log("当前上课的老师没有对应的教学记忆");
      }

      // 若存在匹配老师,学校的教学记忆数据
      if (memoryIndex !== -1) {
        const localPeriod: TeachPeriond[] = localMemory[memoryIndex].period;
        const localNextPeriod: TeachPeriond[] =
          localMemory[memoryIndex].nextPeriod;
        const localMaterial: TeachMaterial[] =
          localMemory[memoryIndex].material;
        const localCourseWare: any[] = localMemory[memoryIndex].courseWare;
        const localCourseWareNext: any[] =
          localMemory[memoryIndex].courseWareNext;
        const type = materialType.value;

        // 如果获取到的本地数据不为空，使用本地存储数据
        if (localPeriod[type] !== null) {
          period.value = localPeriod[type];
          nextPeriod.value = localNextPeriod[type];
          material.value = localMaterial[type];
          material.value.name &&
            (material.value.name = decodeURI(material.value.name));
          courseWarePPT.value = localCourseWare[type];
          courseWarePPTNext.value = localCourseWareNext[type];

          // 2.0高版本缓存，拿取本地存储的记忆课件后，默认地址为线上地址，之后通过cacheFile拿取缓存地址
          if (canUseHightLocalResource.value) {
            console.log(
              "localPeriod:",
              localPeriod[type],
              localNextPeriod[type]
            );
            period.value?.cloud_url &&
              (period.value.pptUrl = period.value.cloud_url);
            nextPeriod.value?.cloud_url &&
              (nextPeriod.value.pptUrl = nextPeriod.value.cloud_url);
          }
          // 格式化最受欢迎ppt数据
          getCourseWareMemory();
          getCourseWarePPT(period.value, true);
          getCourseWarePPT(nextPeriod.value, false);
          // 根据配置的内容去请求本地资源服务，更新数据
          if (localResourceState.value) {
            updateLocalResourceStatus();
          }
        }

        // 放到VUEX中方便管理
        if (
          !store.state.teachMemory.period ||
          store.state.teachMemory.period.length === 0
        ) {
          store.commit(MutationNames.CHANGE_TEACHMEMORY, {
            period: localPeriod,
            nextPeriod: localNextPeriod,
            material: localMaterial,
            courseWare: localCourseWare,
            courseWareNext: localCourseWareNext,
          });
        }
      }
    };

    const updateStatus = (url: string, status: string) => {
      if (url === period.value.pptUrl) {
        period.value.localState = status;
      } else if (url === nextPeriod.value.pptUrl) {
        nextPeriod.value.localState = status;
      } else if (url === courseWarePPTMemory.value.url) {
        courseWarePPTMemory.value.localState = status;
      } else if (url === courseWarePPTMemoryNext.value.url) {
        courseWarePPTMemoryNext.value.localState = status;
      }
    };
    const updateStatusById = (
      id: number,
      status: string,
      url: string,
      progress: number
    ) => {
      if (id === period.value.id) {
        period.value.localState = status;
        if (status === "success") {
          period.value.pptUrl = url;
          period.value.localState = status;
        } else if (status === "loading") {
          period.value.localDownloadProgress = progress;
          period.value.localState = status;
        }
      } else if (id === nextPeriod.value.id) {
        if (status === "success") {
          nextPeriod.value.pptUrl = url;
          nextPeriod.value.localState = status;
        } else if (status === "loading") {
          nextPeriod.value.localDownloadProgress = progress;
          nextPeriod.value.localState = status;
        }
      } else if (id === courseWarePPTMemory.value.id) {
        if (status === "success") {
          courseWarePPTMemory.value.url = url;
          courseWarePPTMemory.value.localState = status;
        } else if (status === "loading") {
          courseWarePPTMemory.value.localDownloadProgress = progress;
          courseWarePPTMemory.value.localState = status;
        }
      } else if (id === courseWarePPTMemoryNext.value.id) {
        if (status === "success") {
          courseWarePPTMemoryNext.value.url = url;
          courseWarePPTMemoryNext.value.localState = status;
        } else if (status === "loading") {
          courseWarePPTMemoryNext.value.localDownloadProgress = progress;
          courseWarePPTMemoryNext.value.localState = status;
        }
      }
    };

    // 整理缓存数据，适应2.0版本缓存
    const handleChangeCacheParams = (data: any) => {
      if (data.length === 0) {
        return data;
      }
      const res = data.map((item: any) => {
        const { id, md5, ...rest } = item;
        return { cloud_id: id, source: "official", type: "PPT", ...rest };
      });
      return { items: res };
    };

    const updateLocalResourceStatus = async () => {
      // 构造参数
      const currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let params = [];
      if (period.value?.id > 0) {
        // 当前课时
        params.push({
          id: period.value.id,
          md5: period.value.md5,
          version_time: period.value.version_time || currentTime,
          url: period.value.cloud_url,
        });
        period.value.localState = period.value?.cloud_url ? "no-download" : "";
      }
      if (nextPeriod.value?.id > 0) {
        params.push({
          id: nextPeriod.value.id,
          md5: nextPeriod.value.md5,
          version_time: nextPeriod.value.version_time || currentTime,
          url: nextPeriod.value.cloud_url,
        });
        nextPeriod.value.localState = nextPeriod.value?.cloud_url
          ? "no-download"
          : "";
      }

      if ((courseWarePPTMemory.value.id ?? 0) > 0) {
        params.push({
          id: courseWarePPTMemory.value.id,
          md5: courseWarePPTMemory.value.md5,
          version_time: courseWarePPTMemory.value.updated_at || currentTime,
          url: courseWarePPTMemory.value.url,
        });
        courseWarePPTMemory.value.localState = courseWarePPTMemory.value?.url
          ? "no-download"
          : "";
      }
      if ((courseWarePPTMemoryNext.value.id ?? 0) > 0) {
        params.push({
          id: courseWarePPTMemoryNext.value.id,
          md5: courseWarePPTMemoryNext.value.md5,
          version_time: courseWarePPTMemoryNext.value.updated_at || currentTime,
          url: courseWarePPTMemoryNext.value.url,
        });
        courseWarePPTMemoryNext.value.localState = "no-download";
      }
      // courseWarePPTMemory.value.forEach((i) => {
      //   if ((i.id ?? 0) > 0) {
      //   params.push({
      //     id: i.id,
      //     md5: i.md5,
      //     version_time: i.updated_at,
      //     url: i.url,
      //   });
      // }
      // });
      // 兼容没有的情况
      if (params.length < 1) {
        return;
      }
      try {
        if (canUseHightLocalResource.value) {
          params = handleChangeCacheParams(params);
        }

        const res: any = await getCacheFile(
          canUseHightLocalResource.value ? params : JSON.stringify(params)
        );

        console.log("resssss0", res);
        res.data.forEach((item: any) => {
          if (item.file_info) {
            if (canUseHightLocalResource.value) {
              if (item.type === "PPT") {
                let currentProgress = 0;
                if (item.file_info.status === "loading") {
                  const { complete_file_num, total_file_num } =
                    item.progress || {};
                  currentProgress = total_file_num
                    ? Math.floor((complete_file_num / total_file_num) * 100)
                    : 0;
                }
                updateStatusById(
                  item.cloud_id,
                  item.file_info.status,
                  item.file_info.file_url,
                  currentProgress
                );
              }
            } else {
              const jsonParam = JSON.parse(item.params);
              const url = jsonParam.url;
              updateStatus(url, item.file_info.status);
            }
          }
        });
      } catch (err) {
        logger.error("更新缓存信息异常", err);
      }
    };

    onMounted(async () => {
      store.commit(MutationNames.CHANGE_TEACHMEMORY, {});
      console.log("当前学校信息", store.state.schoolId, store.state.schoolName);
      console.log("当前老师信息", store.state.teacherId, store.state.nickName);
      console.log("!------ 正在教学记忆");
      console.log(store.state.teachMemory);
      console.log(store.state.teachMemory.period);
      // 初始化教材页面类型,用于区分使用哪个部分的教学记忆数据
      if (props.bookType === "offcial") {
        materialType.value = 0;
      } else if (props.bookType === "characteristicClassroom") {
        materialType.value = 1;
      }
      // 清除快速跳转最受欢迎ppt数据
      store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, "");
      // 初次进入页面使用本地数据
      // 初始化数据 接收来自electron 的消息
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.once("get-teach-memory", (event: any, message: any) => {
          // 接收electron原生返回的信息
          logger.info("我接收到的electron发过来的信息: Memory");
          // 处理获取到的本地数据
          handleLocalMemory(message);
          // 根据配置的内容去请求本地资源服务，更新数据
          if (localResourceState.value) {
            updateLocalResourceStatus();
          }
        });
      }

      // 在客户端运行时实时更新教学记忆进度
      // if (
      //   store.state.teachMemory.period &&
      //   store.state.teachMemory.period.length > 0 &&
      //   store.state.teachMemory.period[materialType.value]
      // ) {
      //   console.log("使用Vuex更新教学记忆数据");
      //   console.log(
      //     "当前教材对应vuex的教学记忆数据是: ",
      //     store.state.teachMemory.period[materialType.value]
      //   );
      //   period.value = store.state.teachMemory.period[materialType.value];
      //   nextPeriod.value =
      //     store.state.teachMemory.nextPeriod[materialType.value];
      //   material.value = store.state.teachMemory.material[materialType.value];
      //   courseWarePPT.value =
      //     store.state.teachMemory.courseWare[materialType.value];
      //   courseWarePPTNext.value =
      //     store.state.teachMemory.courseWareNext[materialType.value];
      //   getCourseWareMemory();
      //   getCourseWarePPT(period.value, true);
      //   getCourseWarePPT(nextPeriod.value, false);
      //   // 根据配置的内容去请求本地资源服务，更新数据
      //   if (localResourceState.value) {
      //     updateLocalResourceStatus();
      //   }
      // }
    });
    onBeforeUnmount(() => {
      socket.off(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
      socket.off(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
    });
    return {
      material,
      period,
      nextPeriod,
      localResourceState,
      courseWarePPT,
      courseWarePPTMemory,
      courseWarePPTMemoryNext,
      handleGotoCourseWarePPT,
      handleLocalResource,
      handleGoToMemory,
      props,
      canUseHightLocalResource,
    };
  },
});
interface Memory {
  period: any;
  nextPeriod: any;
  material: any;
  teacher: number;
  school?: number;
  courseWare: any;
  courseWareNext: any;
}

interface TeachPeriond {
  courseware_count: number;
  courseware_id?: number;
  id: number;
  label: string;
  md5?: string;
  pptUrl?: string;
  cloud_url?: string;
  type?: number;
  version_time?: string;
  localState?: string;
  localDownloadProgress?: number;
}
interface TeachMaterial {
  url: string;
  name: string;
  id: number;
  type: string;
}
interface CourseWareLocalCache {
  url?: string;
  updated_at?: string;
  md5?: string;
  name: string;
  id?: number;
  localState?: string;
  localDownloadProgress?: number;
}

interface CourseWare {
  author_courseware_id?: number;
  author_id?: number;
  author_name?: string;
  author_type?: string;
  book?: any;
  book_id?: number;
  book_section_id?: number;
  courseware_id?: number;
  created_at?: string;
  deleted_at?: any;
  id?: number;
  is_primary?: number;
  last_update_user_id?: number;
  last_update_user_nickname?: string;
  md5?: string;
  name?: string;
  popularity?: any;
  relate_coursewares?: any;
  relation_id?: number;
  section?: any;
  section_breadcrumb?: string;
  share_mode?: number;
  size?: number;
  status?: number;
  tags?: any;
  type?: string;
  updated_at?: string;
  url?: string;
  uuid?: string;
}

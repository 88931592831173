
import {
  defineComponent,
  ref,
  watch,
  toRaw,
  onMounted,
  reactive,
  computed,
  onBeforeMount,
  onUnmounted,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import store, { MutationNames } from "@/store";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import { ElMessage } from "element-plus";
import logger from "@evideo/logger";
import _ from "lodash";
import moment from "moment";
import { eventCenter, event } from "@evideo/frontend-utils";
import {
  getCacheFile,
  uploadCacheFile,
  uploadCacheFileFormData,
} from "@/services/localResource";
import {
  getCoursewares,
  addViewLog,
  addInsViewLog,
  getInstitutionCoursewares,
  getSchoolCoursewares,
  getTeacherInfo,
  getSchoolInstitutionInfo,
  dataEmbeddingPoint,
} from "@/services";
import LocalResourceExchange from "@evideo/local-resource-exchange";
import { inject } from "vue";
import { SocketEventMap } from "@/utils/socket-event";
import {
  checkCurrentCacheDownloading,
  subCurrentCacheDownloadNum,
} from "@/utils/localResourceParse";

export default defineComponent({
  name: "MorePPTModal",
  components: {
    Swiper,
    SwiperSlide,
  },
  emits: ["handleOpenMorePPT"],
  props: {
    bookType: String,
  },
  setup(props, ctx) {
    const message = ref("");
    const canUseHightLocalResource = computed(() =>
      _.gte(store.state.version, "1.4.3")
    );
    const localResourceState = ref(store.state.localResourceState);
    const isAndroid = ref(true);
    // 控制窗口打开
    const currentMoreResourceSectionId = ref();
    const centerDialogVisible = ref(false);
    // tab栏的值
    const TabTypeMap = {
      School: "school",
      Institution: "institution",
      Platform: "platform",
    };
    const mySwiper = ref(null);
    const schoolId = ref();
    const showIns = ref(false);
    const leftBorderFlag = ref(true);
    const rightBorderFlag = ref(true);
    const resourcesEight = reactive([]);
    const resourcesEightTxt = ref("");
    const middleResourcesEight = reactive([]);
    const middleResourcesEightTxt = ref("");
    const lastResourcesEight = reactive([]);
    const lastResourcesEightTxt = ref("");
    let resourcesList = reactive([]);
    const firstLoading = ref(true);
    const currentPage = ref(1);
    const totalPage = ref(1);
    const noDataMsg = ref(false);
    const openMoreDailogSource = ref("outppt");
    const showResourceRef = ref(null);
    const reLoading = ref(false);
    // 是否打开更多资源中的ppt
    const isOpenMorePpt = ref(false);
    // 是否为更多资源页面的ppt预览
    const morePptShow = ref(false);
    // 左侧菜单是否展示
    const dvLeftShow = ref(true);
    const pptNowPlayMode = ref("preview");
    const router = useRouter();
    const isPeriod = ref(true);
    const materialType = ref(0);
    // 选中的ppt
    let clickPptUrl = reactive({
      url: "",
    });
    // 当前tab栏
    const currentTab = ref(TabTypeMap.Platform);

    const socket = inject("socket");

    const downloadStatus = (res) => {
      console.log("#message: ", res);
      const { cloud_url, mode, file_url, cloud_id, type, status } =
        res.data?.details[0] || {};
      if (mode === "moreEight") {
        console.log("resourcesEight.value", resourcesEight);
        resourcesEight.forEach((item) => {
          if (
            decodeURI(item.url) === cloud_url &&
            !canUseHightLocalResource.value
          ) {
            if (status === 0) {
              item.localState = "success";
              item.url = file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = undefined;
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
          if (
            item?.id === cloud_id &&
            item?.type?.toUpperCase() === type &&
            canUseHightLocalResource.value
          ) {
            if (status === 0) {
              item.localState = "success";
              item.url = file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = undefined;
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
        });
      } else if (mode === "moreMiddle") {
        console.log("middleResourcesEight", middleResourcesEight);
        middleResourcesEight.forEach((item) => {
          if (
            decodeURI(item.url) === cloud_url &&
            !canUseHightLocalResource.value
          ) {
            if (status === 0) {
              item.localState = "success";
              item.url = file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = undefined;
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
          if (
            item?.id === cloud_id &&
            item?.type?.toUpperCase() === type &&
            canUseHightLocalResource.value
          ) {
            if (status === 0) {
              item.localState = "success";
              item.url = file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = undefined;
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
        });
      } else if (mode === "moreLast") {
        console.log("lastResourcesEight", lastResourcesEight);
        lastResourcesEight.forEach((item) => {
          if (
            decodeURI(item.url) === cloud_url &&
            !canUseHightLocalResource.value
          ) {
            if (status === 0) {
              item.localState = "success";
              item.url = file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = undefined;
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
          if (
            item?.id === cloud_id &&
            item?.type?.toUpperCase() === type &&
            canUseHightLocalResource.value
          ) {
            if (status === 0) {
              item.localState = "success";
              item.url = file_url;
            } else {
              item.localState = "error";
              item.localDownloadProgress = undefined;
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            }
          }
        });
      }
    };
    const downloadProgress = (res) => {
      console.log("#message progress: ", res);
      if (!canUseHightLocalResource.value) {
        return;
      }
      const { mode, complete_file_num, total_file_num, cloud_id, type } =
        res.data || {};
      const currentProgress = total_file_num
        ? Math.floor((complete_file_num / total_file_num) * 100)
        : 0;
      if (mode === "moreEight") {
        console.log("teachResourceEight", resourcesEight);
        resourcesEight.forEach((item) => {
          if (item?.id === cloud_id && item?.type?.toUpperCase() === type) {
            item.localDownloadProgress = currentProgress;
          }
        });
      } else if (mode === "moreMiddle") {
        console.log("middleResourcesEight", middleResourcesEight);
        middleResourcesEight.forEach((item) => {
          if (item?.id === cloud_id && item?.type?.toUpperCase() === type) {
            item.localDownloadProgress = currentProgress;
          }
        });
      } else if (mode === "moreLast") {
        console.log("lastResourcesEight", lastResourcesEight);
        lastResourcesEight.forEach((item) => {
          if (item?.id === cloud_id && item?.type?.toUpperCase() === type) {
            item.localDownloadProgress = currentProgress;
          }
        });
      }
    };
    socket.on(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
    socket.on(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
    const handleOpenMoreResource = async (data) => {
      console.log("handleOpenMoreResource", data);
      if (props.bookType === "official") {
        materialType.value = 0;
      } else {
        materialType.value = 1;
      }
      if (data.id === store.state.teachMemory.period[materialType.value].id) {
        isPeriod.value = true;
      } else {
        isPeriod.value = false;
      }
      resourcesEightTxt.value = "";
      middleResourcesEightTxt.value = "";
      lastResourcesEightTxt.value = "";
      centerDialogVisible.value = true;
      // 处理打开更多资源的id
      let clickPptUrlData = { id: "" };
      if (data === undefined) {
        if (store.state.pptTreeId) {
          clickPptUrlData.id = store.state.pptTreeId;
        } else {
          clickPptUrlData.id = clickPptUrl.id;
        }
      } else {
        clickPptUrlData.id = data.id;
      }
      // 将当前点击的节点保存到全局变量
      store.commit(MutationNames.CHANGE_MORERESOURCEDATA, clickPptUrlData);
      mySwiper.value.touches.diff = 0;
      currentMoreResourceSectionId.value = clickPptUrlData.id;
      // 处理选中的tab
      if (store.state.moreResourceClickData !== "") {
        // 从全局变量中获取参数
        currentTab.value = store.state.moreResourceTabType;
        currentPage.value = store.state.moreResourceTabPage;
      } else {
        currentTab.value = TabTypeMap.Platform;
        currentPage.value = 1;
      }
      // 滑动模块左边和右边的模块对应的页数
      let leftBorderPage = currentPage.value - 1;
      let rightBorderPage = currentPage.value + 1;
      if (currentPage.value === 1) {
        leftBorderPage = 2;
        rightBorderPage = 3;
      }
      logger.info("调接口前查看tab值===》", currentMoreResourceSectionId.value);
      const searchFunc = getResourceFuncByTabType();
      const res = await searchFunc({
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        page: currentPage.value,
        is_primary: 0,
      });
      console.log("获取更多资源的数据", res);
      let finishedData = res;
      // 当版本支持本地缓存时启用本地缓存
      if (localResourceState.value) {
        finishedData = await getLocalResourceState(res);
      }
      console.log("获取更多资源的数据aa", finishedData);
      console.log("获取更多资源的数据bb", resourcesEight);
      if (currentPage.value % 3 === 1) {
        resourcesEight.length = 0;
        resourcesEight.push(...finishedData.data.items);
        mySwiper.value.activeIndex = 1;
      } else if (currentPage.value % 3 === 2) {
        middleResourcesEight.length = 0;
        middleResourcesEight.push(...finishedData.data.items);
        mySwiper.value.activeIndex = 2;
      } else {
        lastResourcesEight.length = 0;
        lastResourcesEight.push(...finishedData.data.items);
        mySwiper.value.activeIndex = 3;
      }
      totalPage.value = finishedData.data.meta.last_page;
      if (totalPage.value === 0) {
        totalPage.value = 1;
      }
      // 选择类型或搜索之后判断边界值
      if (currentPage.value === 1 && totalPage.value !== 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = true;
      } else if (
        currentPage.value === totalPage.value &&
        totalPage.value === 1
      ) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = false;
      } else if (
        currentPage.value === totalPage.value &&
        totalPage.value !== 1
      ) {
        leftBorderFlag.value = true;
        rightBorderFlag.value = false;
      } else {
        leftBorderFlag.value = true;
        rightBorderFlag.value = true;
      }
      const middleData = await searchFunc({
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        page: leftBorderPage,
        is_primary: 0,
      });
      console.log("获取更多资源的数据", middleData);
      let finishedMiddleData = middleData;
      // 当版本支持本地缓存时启用本地缓存
      if (localResourceState.value) {
        finishedMiddleData = await getLocalResourceState(middleData);
      }
      console.log("获取更多资源的数据aa", finishedMiddleData);
      // 在这里判断数据放到哪一个窗口
      if (leftBorderPage % 3 === 1) {
        resourcesEight.length = 0;
        resourcesEight.push(...finishedMiddleData.data.items);
      }
      if (leftBorderPage % 3 === 2) {
        middleResourcesEight.length = 0;
        middleResourcesEight.push(...finishedMiddleData.data.items);
      }
      if (leftBorderPage % 3 === 0) {
        lastResourcesEight.length = 0;
        lastResourcesEight.push(...finishedMiddleData.data.items);
      }
      totalPage.value = finishedMiddleData.data.meta.last_page;
      if (totalPage.value === 0) {
        totalPage.value = 1;
      }
      const lastData = await searchFunc({
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        page: rightBorderPage,
        is_primary: 0,
      });
      console.log("获取更多资源的数据", lastData);
      let finishedLastData = lastData;
      // 当版本支持本地缓存时启用本地缓存
      if (localResourceState.value) {
        finishedLastData = await getLocalResourceState(lastData);
      }
      console.log("获取更多资源的数据aa", finishedLastData);
      // 在这里判断数据放到哪一个窗口
      if (rightBorderPage % 3 === 1) {
        resourcesEight.length = 0;
        resourcesEight.push(...finishedLastData.data.items);
      }
      if (rightBorderPage % 3 === 2) {
        middleResourcesEight.length = 0;
        middleResourcesEight.push(...finishedLastData.data.items);
      }
      if (rightBorderPage % 3 === 0) {
        lastResourcesEight.length = 0;
        lastResourcesEight.push(...finishedLastData.data.items);
      }
      totalPage.value = finishedLastData.data.meta.last_page;
      if (totalPage.value === 0) {
        totalPage.value = 1;
      }
      if (resourcesEight.length === 0) {
        logger.info("resourcesEight.length === 0");
        resourcesEightTxt.value = "暂无数据";
        noDataMsg.value = true;
      } else {
        resourcesEightTxt.value = "";
      }
      if (middleResourcesEight.length === 0) {
        middleResourcesEightTxt.value = "暂无数据";
        noDataMsg.value = true;
      } else {
        middleResourcesEightTxt.value = "";
      }
      if (lastResourcesEight.length === 0) {
        lastResourcesEightTxt.value = "暂无数据";
        noDataMsg.value = true;
      } else {
        lastResourcesEightTxt.value = "";
      }
      firstLoading.value = false;
      if (currentPage.value % 3 === 1) {
        mySwiper.value.activeIndex = 1;
      } else if (currentPage.value % 3 === 2) {
        mySwiper.value.activeIndex = 2;
      } else {
        mySwiper.value.activeIndex = 3;
      }
      openMoreDailogSource.value = "outppt";
      _.merge(clickPptUrl, {
        runtime: {
          state: {
            screenType: "preview",
          },
        },
      });
      setTimeout(() => {
        if (store.state.moreResourceClickData !== "") {
          logger.info(
            "还原更多资源点击事件",
            store.state.moreResourceClickData
          );
          handleCardClick(store.state.moreResourceClickData);
        }
      }, 1000);
    };
    const closeCenterDialog = () => {
      console.log("关闭", resourcesEight);
      centerDialogVisible.value = false;
      currentTab.value = TabTypeMap.Platform;
      store.commit(
        MutationNames.CHANGE_MORERESOURCETABTYPE,
        TabTypeMap.Platform
      );
      currentMoreResourceSectionId.value = undefined;
      resourcesEight.length = 0;
      middleResourcesEight.length = 0;
      lastResourcesEight.length = 0;
      noDataMsg.value = false;
      resourcesEightTxt.value = "";
      middleResourcesEightTxt.value = "";
      lastResourcesEightTxt.value = "";
    };

    const getTeacherData = () => {
      getTeacherInfo({}).then((data) => {
        schoolId.value = data.school_id;
      });
    };

    const getInsInfo = () => {
      getSchoolInstitutionInfo(schoolId.value).then(({ data }) => {
        if (data && data.uplevel_ins_data && data.uplevel_ins_data.length > 0) {
          showIns.value = true;
        }
      });
    };

    const handleTabClick = async (type) => {
      store.commit(MutationNames.CHANGE_MORERESOURCETABTYPE, type);
      mySwiper.value.touches.diff = 0;
      if (currentTab.value !== type) {
        currentTab.value = type;
        const params = {
          book_section_id: currentMoreResourceSectionId.value,
          per_page: 8,
          is_primary: 0,
        };
        const searchFunc = getResourceFuncByTabType();
        // begin await test
        const res = await searchFunc({ ...params, page: 1 });
        let finishedResData = res;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedResData = await getLocalResourceState(res);
        }
        resourcesEight.length = 0;
        resourcesList.length = 0;
        resourcesList = finishedResData.data.items;
        resourcesEight.push(...resourcesList.slice(0, 8));
        if (resourcesEight.length === 0) {
          resourcesEightTxt.value = "暂无数据";
          noDataMsg.value = true;
        } else {
          resourcesEightTxt.value = "";
        }
        // totalPage.value = parseInt(resourcesList.length / 8);
        // totalPage.value = Math.ceil(resourcesList.length / 8);
        totalPage.value = res.data.meta.last_page;
        if (totalPage.value === 0) {
          totalPage.value = 1;
        }
        // 选择类型或搜索之后判断边界值
        if (currentPage.value == 1 && totalPage.value !== 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = true;
        } else if ((currentPage.value == totalPage.value) == 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
        const middleRes = await searchFunc({ ...params, page: 2 });
        let finishedMiddleResData = middleRes;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedMiddleResData = await getLocalResourceState(middleRes);
        }
        middleResourcesEight.length = 0;
        resourcesList.length = 0;
        resourcesList = finishedMiddleResData.data.items;
        middleResourcesEight.push(...resourcesList.slice(0, 8));
        if (middleResourcesEight.length === 0) {
          middleResourcesEightTxt.value = "暂无数据";
          noDataMsg.value = true;
        } else {
          middleResourcesEightTxt.value = "";
        }
        // totalPage.value = parseInt(resourcesList.length / 8);
        // totalPage.value = Math.ceil(resourcesList.length / 8);
        totalPage.value = middleRes.data.meta.last_page;
        if (totalPage.value === 0) {
          totalPage.value = 1;
        }
        const lastRes = await searchFunc({ ...params, page: 2 });
        let finishedLastResData = lastRes;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedLastResData = await getLocalResourceState(lastRes);
        }
        lastResourcesEight.length = 0;
        resourcesList.length = 0;
        resourcesList = finishedLastResData.data.items;
        lastResourcesEight.push(...resourcesList.slice(0, 8));
        if (lastResourcesEight.length === 0) {
          lastResourcesEightTxt.value = "暂无数据";
          noDataMsg.value = true;
        } else {
          lastResourcesEightTxt.value = "";
        }
        totalPage.value = lastRes.data.meta.last_page;
        if (totalPage.value === 0) {
          totalPage.value = 1;
        }
        currentPage.value = 1;
        mySwiper.value.activeIndex = 1;
        openMoreDailogSource.value = "outppt";
        _.merge(clickPptUrl, {
          runtime: {
            state: {
              screenType: "preview",
            },
          },
        });
      }
    };
    const onSwiper = (swiper) => {
      logger.info("swiper", swiper);
    };
    const onSlideChange = () => {
      logger.info("当前页码是===>", currentPage.value);
      if (mySwiper.value) {
        logger.info("当前页码是===>", mySwiper.value.activeIndex);
      }
    };
    const onSlidePrevTransitionEnd = () => {
      const currentActiveIndex = mySwiper.value.activeIndex;
      const nextPage = currentPage.value - 1;
      const nextPageMOD = nextPage % 3;
      const currentPageMOD = currentActiveIndex % 3;
      if (nextPageMOD === currentPageMOD) {
        mySwiper.value.touches.diff = 0;
        currentPage.value--;
        if (currentPage.value !== 1) {
          getPrevData(currentPage.value - 1);
        }
      }
    };
    const onSlideNextTransitionEnd = () => {
      if (!firstLoading.value) {
        const currentActiveIndex = mySwiper.value.activeIndex;
        const nextPage = currentPage.value + 1;
        const nextPageMOD = nextPage % 3;
        const currentPageMOD = currentActiveIndex % 3;
        if (nextPageMOD === currentPageMOD) {
          mySwiper.value.touches.diff = 0;
          currentPage.value++;
          if (currentPage.value !== totalPage.value) {
            getNextData(currentPage.value + 1);
          }
          // getNextData(currentPage.value + 1, params);
        }
      }
    };

    // 更多资源中的资源点击事件
    const handleCardClick = (data) => {
      logger.info("handleCardClick", data);
      store.commit(MutationNames.CHANGE_MORERESOURCETABPAGE, currentPage.value);
      let index = 0;
      if (props.bookType === "official") {
        index = 0;
      } else {
        index = 1;
      }
      let book = store.state.teachMemory.material[index];
      store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, data);
      if (isPeriod.value) {
        router.push({
          path: "/materialPpt",
          query: {
            bookId: book.id,
            url: book.url,
            name: encodeURI(book.name),
            firstLoadType: "false",
            teachMaterialType: book.type,
            memory: "morePeriod",
          },
        });
      } else {
        router.push({
          path: "/materialPpt",
          query: {
            bookId: book.id,
            url: book.url,
            name: encodeURI(book.name),
            firstLoadType: "false",
            teachMaterialType: book.type,
            memory: "morePeriodNext",
          },
        });
      }
    };
    //  点击按钮切换到上一页
    const previous = _.debounce(() => {
      if (currentPage.value === 1) {
        message.value = "已经是第一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slidePrev();
        // currentPage.value--;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // logger.info("indexpre", startIndex, endIndex);
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);

    //  点击按钮切换到下一页
    const next = _.debounce(() => {
      if (currentPage.value === totalPage.value) {
        message.value = "已经是最后一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slideNext();
        // currentPage.value++;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);

    //  切换时获取上一页数据的函数
    const getPrevData = (page) => {
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        // is_primary: 0,
        // per_page: 8,
        // page: page,
        // book_show_modules: 1,
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        page: page,
        is_primary: 0,
      }).then(async (data) => {
        let finishedData = data;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        const switchTeachMaterialList = finishedData.data.items;
        if ((currentPage.value - 1) % 3 == 1) {
          resourcesEight.length = 0;
          resourcesEight.push(...switchTeachMaterialList);
          if (resourcesEight.length === 0) {
            resourcesEightTxt.value = "暂无数据";
          } else {
            resourcesEightTxt.value = "";
          }
        }
        if ((currentPage.value - 1) % 3 == 2) {
          middleResourcesEight.length = 0;
          middleResourcesEight.push(...switchTeachMaterialList);
          if (middleResourcesEight.length === 0) {
            middleResourcesEightTxt.value = "暂无数据";
          } else {
            middleResourcesEightTxt.value = "";
          }
        }
        if ((currentPage.value - 1) % 3 == 0) {
          lastResourcesEight.length = 0;
          lastResourcesEight.push(...switchTeachMaterialList);
          if (lastResourcesEight.length === 0) {
            lastResourcesEightTxt.value = "暂无数据";
          } else {
            lastResourcesEightTxt.value = "";
          }
        }
      });
    };

    //  切换时获取下一页数据的函数
    const getNextData = (page) => {
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        // is_primary: 0,
        // per_page: 8,
        // page: page,
        // book_show_modules: 1,
        book_section_id: currentMoreResourceSectionId.value,
        per_page: 8,
        // page: leftBorderPage,
        page: page,
        is_primary: 0,
      }).then(async (data) => {
        let finishedData = data;
        // 当版本支持本地缓存时启用本地缓存
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        const switchTeachMaterialList = finishedData.data.items;
        if ((currentPage.value + 1) % 3 == 1) {
          resourcesEight.length = 0;
          resourcesEight.push(...switchTeachMaterialList);
          if (resourcesEight.length === 0) {
            resourcesEightTxt.value = "暂无数据";
          } else {
            resourcesEightTxt.value = "";
          }
        }
        if ((currentPage.value + 1) % 3 == 2) {
          middleResourcesEight.length = 0;
          middleResourcesEight.push(...switchTeachMaterialList);
          if (middleResourcesEight.length === 0) {
            middleResourcesEightTxt.value = "暂无数据";
          } else {
            middleResourcesEightTxt.value = "";
          }
        }
        if ((currentPage.value + 1) % 3 == 0) {
          lastResourcesEight.length = 0;
          lastResourcesEight.push(...switchTeachMaterialList);
          if (lastResourcesEight.length === 0) {
            lastResourcesEightTxt.value = "暂无数据";
          } else {
            lastResourcesEightTxt.value = "";
          }
        }
      });
    };

    // 资源类型
    const bindIcon = (icon) => {
      return require("@/assets/img/myCourseWare/courseWare/" + icon + ".png");
    };

    // 根据type类型返回对应的source
    const sourceUtil = (type) => {
      if (type === "moreMiddle" || type === "institution") {
        return "ins";
      } else if (type === "moreLast" || type === "school") {
        return "school";
      } else {
        return "official";
      }
    };

    const handleUpdateData = (type, data, updateObj) => {
      // type 区分是更多资源触发还是章节列表触发 tree|章节列表 more|更多资源
      if (type === "moreEight") {
        const newData = handleLocalUpdateMoreData(data.id, "eight", updateObj);
        console.log("修改更多第一页数据", newData);
        resourcesEight.length = 0;
        resourcesEight.push(...newData);
      } else if (type === "moreLast") {
        const newData = handleLocalUpdateMoreData(data.id, "last", updateObj);
        console.log("修改更多最后页数据", newData);
        lastResourcesEight.length = 0;
        lastResourcesEight.push(...newData);
      } else {
        const newData = handleLocalUpdateMoreData(data.id, "middle", updateObj);
        console.log("修改更多中间页数据", newData);
        middleResourcesEight.length = 0;
        middleResourcesEight.push(...newData);
      }
    };

    // 处理该教材需要确认的资源数据
    const handleLocalResource = (data, type) => {
      if (canUseHightLocalResource.value) {
        if (!checkCurrentCacheDownloading()) {
          return;
        }
        console.log("下载到本地", data, type);
        const params = {
          url: data.url,
          version_time: data.updated_at,
          cloud_id: data.id,
          name:
            data.type === "ppt"
              ? data.name + ".json"
              : data.name +
                "." +
                data.url.substring(data.url.lastIndexOf(".") + 1),
          mode: type,
          source: sourceUtil(type),
          type: data.type,
        };
        window.requestIdleCallback(() => {
          LocalResourceExchange.resourceParse(params)
            .then((res) => {
              let formData = new FormData();
              formData.append("version_time", params.version_time);
              formData.append("mode", params.mode); // 参考socket里的mode形式
              formData.append("cloud_id", params.cloud_id);
              formData.append("name", params.name);
              if (params.type === "ppt") {
                formData.append("resource_file", res);
              } else {
                formData.append("resource_file_url", params.url);
              }

              formData.append("type", params.type.toUpperCase());
              formData.append("source", params.source);
              uploadCacheFileFormData(formData)
                .then((_res) => {
                  handleUpdateData(type, data, { localDownloadProgress: 0 });
                  console.log("创建缓存资源下载成功:", _res);
                })
                .catch((err) => {
                  console.error(err);
                  resetLocalResource();
                  ElMessage.error({
                    offset: 200,
                    message: "资源下载失败",
                    type: "error",
                  });
                });
            })
            .catch((err) => {
              console.error(err);
              resetLocalResource();
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            })
            .finally(() => {
              subCurrentCacheDownloadNum();
            });
        });
      } else {
        console.log("下载到本地", data, type);
        const params = [
          {
            url: data.url,
            version_time: data.updated_at,
            md5: data.md5,
            id: data.id,
            name: data.name,
            mode: type,
          },
        ];
        console.log("params", params);
        uploadCacheFile(params);
      }
      handleUpdateData(type, data, { localState: "downloading" });
    };

    const getResourceFuncByTabType = () => {
      let func = undefined;
      switch (currentTab.value) {
        case TabTypeMap.Platform:
          func = getCoursewares;
          break;
        case TabTypeMap.Institution:
          func = getInstitutionCoursewares;
          break;
        case TabTypeMap.School:
          func = getSchoolCoursewares;
          break;
        default:
          func = getCoursewares;
          break;
      }
      return func;
    };

    // 更多资源获取本地是否有缓存资源
    const getLocalResourceState = async (data) => {
      let res = data;
      console.log("获取更多资源的数据data", currentTab.value, data.data.items);
      let moreResource = [];
      data.data.items.forEach((el) => {
        // const newNode = {
        //   id: el.id,
        //   md5: el.md5,
        //   version_time: el.updated_at,
        //   url: el.url,
        // };
        if (canUseHightLocalResource.value) {
          moreResource.push({
            cloud_id: el.id,
            version_time: el.updated_at,
            url: el.url,
            source: sourceUtil(currentTab.value),
            type: el.type.toUpperCase(),
          });
        } else {
          moreResource.push({
            id: el.id,
            md5: el.md5,
            version_time: el.updated_at,
            url: el.url,
          });
        }
        // moreResource.push(newNode);
      });
      console.log("获取更多资源的数据moreResource", moreResource);
      // 将moreResource发送给缓存服务换回数据 在数据里增加localState
      const localMoreResource = await isLocalCacheData(
        canUseHightLocalResource.value ? { items: moreResource } : moreResource
      );
      console.log("aa", localMoreResource);

      // 将缓存服务返回的数据拼回传入的数据并且返回回去
      res.data.items.forEach((el, index) => {
        let flag = false;
        localMoreResource.data.find((data) => {
          let currentProgress = 0;
          const { status, file_url } = data.file_info || {};
          if (status === "loading") {
            const { complete_file_num, total_file_num } = data.progress || {};
            currentProgress = total_file_num
              ? Math.floor((complete_file_num / total_file_num) * 100)
              : 0;
          }

          if (el.id === data.id) {
            if (status && (status === "success" || status === "loading")) {
              res.data.items[index].url = file_url;
              res.data.items[index].localState = status;
              res.data.items[index].localDownloadProgress = currentProgress;
              flag = true;
              return true;
            }
          }

          if (el.id === data.cloud_id) {
            if (status && (status === "success" || status === "loading")) {
              res.data.items[index].url = file_url;
              res.data.items[index].localState = status;
              res.data.items[index].localDownloadProgress = currentProgress;
              flag = true;
              return true;
            }
          }
        });
        if (!flag) {
          res.data.items[index].localState = "no-download";
          res.data.items[index].localDownloadProgress = undefined;
        }
      });
      console.log("res1112", res);
      return res;
    };

    const resetLocalResource = (id, type) => {
      let data = [];
      if (type === "eight") {
        data = _.cloneDeep(resourcesEight);
      } else if (type === "last") {
        data = _.cloneDeep(lastResourcesEight);
      } else {
        data = _.cloneDeep(middleResourcesEight);
      }

      data.map((el) => {
        if (el.id === id) {
          el["localState"] = "no-download";
          el["localDownloadProgress"] = undefined;
        }
        return el;
      });

      if (type === "eight") {
        resourcesEight.length = 0;
        resourcesEight.push(...data);
      } else if (type === "last") {
        lastResourcesEight.length = 0;
        lastResourcesEight.push(...data);
      } else {
        middleResourcesEight.length = 0;
        middleResourcesEight.push(...data);
      }
    };

    // 下载资源到本地,修改更多资源的数据
    const handleLocalUpdateMoreData = (id, type, updateObj) => {
      let data = [];
      if (type === "eight") {
        data = resourcesEight;
      } else if (type === "last") {
        data = lastResourcesEight;
      } else {
        data = middleResourcesEight;
      }
      let params = [];
      data.forEach((el) => {
        let newNode = el;
        if (newNode.id === id) {
          // newNode["localState"] = "downloading";
          for (const key in updateObj) {
            newNode[key] = updateObj[key];
          }
        }
        // console.log("newNode前", newNode);
        newNode = JSON.parse(JSON.stringify(newNode));
        // console.log("newNode后", newNode);
        params.push(newNode);
      });
      return params;
    };

    // 向缓存服务确认资源是否在本地已存在
    const isLocalCacheData = (data) => {
      return getCacheFile(JSON.stringify(data));
    };
    //  初始化滑屏

    const init = () => {
      mySwiper.value = document.querySelector(".swiper").swiper;
    };

    // 数据埋点
    const handleDataReport = (item, type) => {
      let eventattr = { id: item.id };
      if (type === "more") {
        let ownerType = "";
        if (currentTab.value === "platform") {
          ownerType = "official";
        } else if (currentTab.value === "institution") {
          ownerType = "institution";
        } else {
          ownerType = "school";
        }
        eventattr = {
          id: item.id,
          owner_type: ownerType,
        };
      }
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (!isAndroid.value) {
        // pc数据上报
        const data = {
          uid: store.state.pcUserIdAES,
          ct: "96",
          ci: store.state.mac,
          mac: store.state.mac,
          sv: store.state.version,
          fv: store.state.hardwareModel,
          ca: ca,
          pt: "pc",
          eventid: "teaching_teachmaterial_open",
          pageid: "teachmaterial",
          moduleid: "teaching",
          eventattr: eventattr,
        };
        const body = encodeURIComponent(JSON.stringify(data));
        dataEmbeddingPoint(body);
      } else {
        // android数据上报
        window.WebViewJavascriptBridge.callHandler(
          "getDeviceInfo", // native中注册时的key值
          null, // native中注册方法中的传入的数据
          function (responseData) {
            // 这里打印的应该是上面Handler实现方法中的callback的入参
            const data = JSON.parse(responseData);
            data.ca = ca;
            data.pt = "android";
            data.eventid = "teaching_teachmaterial_open";
            data.pageid = "teachmaterial";
            data.moduleid = "teaching";
            data.eventattr = eventattr;
            const body = encodeURIComponent(JSON.stringify(data));
            dataEmbeddingPoint(body);
          }
        );
      }
    };

    // 更多资源中页数变化的时候将当前页数保存到全局变量
    watch(
      () => currentPage.value,
      (newVal) => {
        store.commit(MutationNames.CHANGE_MORERESOURCETABPAGE, newVal);
        logger.info("更多资源的页数进行了全局变量的保存", newVal);
      }
    );

    watch(
      () => currentPage.value,
      () => {
        if (currentPage.value === 1) {
          leftBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
        }
      },
      { immediate: true }
    );

    watch(
      () => currentPage.value,
      () => {
        if (currentPage.value === totalPage.value) {
          rightBorderFlag.value = false;
        } else {
          rightBorderFlag.value = true;
        }
      }
    );

    watch(
      () => mySwiper.value,
      _.debounce(() => {
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX > 0 &&
          mySwiper.value.touches.diff > 0 &&
          currentPage.value === 1
        ) {
          message.value = "已经是第一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX < 0 &&
          mySwiper.value.touches.diff < 0 &&
          currentPage.value === totalPage.value
        ) {
          message.value = "已经是最后一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
      }, 200),
      { deep: true }
    );

    onMounted(async () => {
      // console.time("初始化数据");
      // if (window.require) {
      //   const { ipcRenderer } = window.require("electron");
      //   logger.info("ipcRenderer", ipcRenderer);
      //   ipcRenderer.on("pptGoBack", (event, message) => {
      //     // 接收electron原生返回的信息
      //     logger.info("我接收到的electron发过来的信息", event, message);
      //     goBack();
      //   });
      // }
      // window.MaterialPptGoBack = goBack;
      // if (firstLoadType === "true") {
      //   loadPPT();
      // } else {
      //   const bookId = route.query.bookId;
      //   const bookUrl = route.query.url;
      //   const bookName = route.query.name;
      //   if (bookType.value === "official") {
      //     data = await getSections(bookId);
      //   }
      //   if (bookType.value === "characteristicClassroom") {
      //     data = await getSections(bookId);
      //   }
      //   if (bookType.value === "myCourse") {
      //     data = await getTeacherSections(bookId);
      //   }
      //   initializationTreeData.value = data.data;
      //   // 处理数据获取是否有本地缓存
      //   console.log("localResourceState", localResourceState.value);
      //   if (localResourceState.value) {
      //     finishedLocalCacheData.value = await handleLocalCacheData(data.data);
      //     console.log("本地缓存1", finishedLocalCacheData.value);
      //     const LocalCacheData = await isLocalCacheData(
      //       finishedLocalCacheData.value
      //     );
      //     console.log("本地缓存2", LocalCacheData);
      //     finishedLocalCacheData.value = LocalCacheData.data;
      //   }
      //   console.log("treedataeeaa", finishedLocalCacheData.value);
      //   const courseData = data.data || [];
      //   const newTreeData = filterParams(courseData);
      //   console.log("newTreeData", newTreeData);
      //   firstParams = firstTreeForeach(courseData);
      //   console.log("开始处理教材树的数据firstParams", firstParams);
      //   if (firstParams !== null) {
      //     // 将数据和本地是否有资源的数据进行比较，重新处理数据
      //     let firstPPTUrl = firstParams.url;
      //     finishedLocalCacheData.value.find((data) => {
      //       console.log("获取了本地");
      //       if (
      //         data.id === firstParams.id &&
      //         data.file_info?.status === "success"
      //       ) {
      //         // 本地缓存资源数据中存在（不管本地资源是否存在）就从本地缓存资源数据中取url地址
      //         console.log("获取了本地的url11");
      //         firstPPTUrl = data.url;
      //         return true;
      //       } else {
      //         console.log("获取了本地的url22");
      //         firstPPTUrl = firstParams.url;
      //       }
      //     });
      //     _.merge(firstPptUrl, {
      //       id: firstParams.id,
      //       url: firstPPTUrl,
      //       runtime: {
      //         demMoreResourceBtn: firstParams.demMoreResourceBtn,
      //         state: {
      //           slideIndex: 0,
      //           screenType: "preview",
      //           screening: true,
      //         },
      //       },
      //     });
      //     previousPptUrl = firstPptUrl;
      //     nextPptUrl = firstPptUrl;
      //     _.merge(clickPptUrl, {
      //       id: firstParams.id,
      //       url: firstPPTUrl,
      //       runtime: {
      //         demMoreResourceBtn: firstParams.demMoreResourceBtn,
      //         state: {
      //           slideIndex: 0,
      //           screenType: "preview",
      //           screening: true,
      //         },
      //       },
      //     });
      //   } else {
      //     pptLoading.value = true;
      //   }
      //   treeData.value = newTreeData;
      //   console.log("treeData.value", treeData.value);
      //   url.value = bookUrl;
      //   materialName.value = bookName;
      //   if (firstLoadType === "false") {
      //     setTimeout(() => {
      //       loadPPT();
      //     }, 800);
      //   }
      //   getInsInfo();
      // }
      // // 接收子应用全屏命令
      // eventCenter.sub(event.COMMON_FULL_SCREEN, (res) => {
      //   //           pptNowPlayMode.value = "preview";
      //   logger.info("全屏");
      //   // res:获取事件a的参数
      //   if (res.fullScreen) {
      //     dvLeftShow.value = false;
      //     fullScreen.value = true;
      //     if (morePptShow.value) {
      //       pptNowPlayMode.value = "demonstrate";
      //     }
      //     // enlarge();
      //   } else {
      //     // ppt资源预览时，隐藏左边栏
      //     if (morePptShow.value) {
      //       pptNowPlayMode.value = "preview";
      //     } else {
      //       dvLeftShow.value = true;
      //       fullScreen.value = false;
      //     }
      //     // if (!morePptShow.value) {
      //     //   dvLeftShow.value = true;
      //     //   pptNowPlayMode.value = "preview";
      //     // } else {
      //     //   pptNowPlayMode.value = "preview";

      //     //   fullScreen.value = false;
      //     // }
      //   }
      // });
      // // 接收子应用message
      // eventCenter.sub(event.COMMON_NOTIFY_MESSAGE, (res) => {
      //   logger.info("ppt发过来的消息", res);
      //   const { type, element } = res;
      //   antdMessage.config(
      //     {
      //       top: `100px`,
      //       maxCount: 3,
      //       getContainer: () => element || document.body,
      //     },
      //     10
      //   );
      //   // const msg = res.message;
      //   const msg = (
      //     <span style="margin-left:30px; font-size:30px">{res.message}</span>
      //   );
      //   let msgFunc = null;
      //   switch (type) {
      //     case "info":
      //       msgFunc = antdMessage.info;
      //       break;
      //     case "error":
      //       msgFunc = antdMessage.error;
      //       break;
      //     case "success":
      //       msgFunc = antdMessage.success;
      //       break;
      //     case "warning":
      //       msgFunc = antdMessage.warning;
      //       break;
      //     default:
      //       msgFunc = antdMessage.info;
      //   }
      //   msgFunc &&
      //     msgFunc(msg, 3, () => {
      //       antdMessage.destroy();
      //     });
      // });
      // eventCenter.sub("openMoreDailog", ({ slideIndex = 0 } = {}) => {
      //   logger.info("slideIndex", slideIndex);
      //   handleOpenMoreResource(previousPptUrl);
      //   _.merge(clickPptUrl, {
      //     runtime: {
      //       state: {
      //         slideIndex,
      //         screenType: "demonstrate",
      //       },
      //     },
      //   });
      //   openMoreDailogSource.value = "inppt";
      // });
      // // 接收子应用翻页命令
      // eventCenter.sub("PPT_DOUBLE_CLICK_PAGE_BTN", (res) => {
      //   logger.info("翻页的res==>", res);
      //   // 向子应用发送消息
      //   if (res.type === "previous") {
      //     logger.info("在这里加载上一章的最后一节");
      //     handlePreviousPPT();
      //   }
      //   if (res.type === "next") {
      //     logger.info("在这里加载下一章的第一节");
      //     handleNextPPT();
      //   }
      // });
      // 滑屏初始化
      getInsInfo();
      init();
      // console.timeEnd("初始化数据");
      // logger.info(
      //   "store里取出来的minimizePPTInfo===>",
      //   store.state.minimizePPTInfo
      // );
      // logger.info("store里取出来的minimizeUrl===>", store.state.minimizeUrl);
      ctx.emit("handleOpenMorePPT", handleOpenMoreResource);
    });

    onBeforeMount(() => {
      getTeacherData();
    });

    onUnmounted(async () => {
      // store.commit(MutationNames.CHANGE_PPTTREEID, previousPptUrl.id);
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.removeAllListeners(["pptGoBack"]);
      }
      socket.off(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
      socket.off(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);

      // 离开页面时获取当前选中ppt的信息
      eventCenter.send("PPT_GET_STATUS", {}, (res) => {
        logger.info("页面卸载时保存ppt的信息", res);
        logger.info("页面卸载时isOpenMorePpt", isOpenMorePpt.value);
        // 是否打开更多资源中的ppt
        if (isOpenMorePpt.value) {
          // 保存到更多资源ppt
          store.commit(MutationNames.CHANGE_MOREPPTINFO, res);
          logger.info("两个ppt的状态更多的ppt", store.state.morePPTInfo);
          logger.info("两个ppt的状态树的ppt", store.state.minimizePPTInfo);
        } else {
          // 清除更多资源的全局变量
          store.commit(MutationNames.CHANGE_MORERESOURCEDATA, "");
          // 清除更多资源中点击资源的全局变量
          store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, "");
          // 清除保存到更多资源ppt
          store.commit(MutationNames.CHANGE_MOREPPTINFO, "");
          // 保存到ppt
          store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, res);
        }
      });
      // setTimeout(async () => {
      //   // 用于pc端，goback返回时卸载ppt
      //   if (!isAndroid.value) {
      //     if (pptFun) {
      //       if (pptFun.preUnmount) {
      //         await pptFun.preUnmount();
      //       }
      //       pptFun.unmount();
      //       logger.info("卸载成功");
      //     } else {
      //       logger.info("没有卸载直接返回");
      //     }
      //   }
      // }, 100);
    });

    return {
      canUseHightLocalResource,
      mySwiper,
      centerDialogVisible,
      TabTypeMap,
      currentTab,
      showIns,
      leftBorderFlag,
      rightBorderFlag,
      resourcesEight,
      resourcesEightTxt,
      middleResourcesEight,
      middleResourcesEightTxt,
      lastResourcesEight,
      lastResourcesEightTxt,
      resourcesList,
      localResourceState,
      noDataMsg,
      currentPage,
      totalPage,
      closeCenterDialog,
      handleTabClick,
      onSwiper,
      onSlideChange,
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      bindIcon,
      handleLocalResource,
      handleCardClick,
      previous,
      next,
    };
  },
});
